function initHeader() {
  const menuBtn = document.getElementById("menu-opener");
  const header = document.getElementById("header");

  menuBtn.addEventListener('click', function () {
    header.classList.toggle('is-open');
    document.body.classList.toggle('stop-scroll-mobile');
  });
}

function initServiceItems() {
  const serviceItemOpeners = document.getElementsByClassName('service-item__opener');

  Array.from(serviceItemOpeners).forEach(element => {
    element.addEventListener('click', function (e) {
      const opener = e.currentTarget;
      const itemId = opener.dataset.id;
      const item = document.getElementById(itemId);

      item.classList.toggle('is-open');
    });
  });
}

function setupScrollEvent() {
  const scrollButton = document.querySelector('.scroll-top-btn');

  scrollButton.addEventListener('click', (e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
}

function setupScrollListener() {
  window.addEventListener('scroll', (e) => {
    const scrollBreakpoint = window.innerHeight * 0.9;
    const scrollButton = document.querySelector('.scroll-top-btn');
    const scrollOffset = window.scrollY;

    if (scrollOffset >= scrollBreakpoint) {
      scrollButton.classList.add('visible');
    } else if (scrollOffset <= 0) {
      scrollButton.classList.remove('visible');
    }
  });
}

document.addEventListener('DOMContentLoaded', function () {
  initHeader();
  initServiceItems();
  setupScrollEvent();
  setupScrollListener();
  Fancybox.bind();
});
